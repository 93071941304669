<!--
File: Budgets.vue
Description: show list of budgets entered in the DB.
-->
<template>
  <div>
    <md-card v-show="!showTreatmentsForm">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout" style="align-items: center; justify-content: flex-start; width: auto;">
          <div class="md-layout-item md-small-size-20 md-size-10">
            <BaseDropdown :label="$t('budget.year')" v-model="selectedWorklist" :items="savedWorksList"
              :displayField="'year'" :valueField="'work_list_id'" :is_required="false" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
              :is_required="false" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown :label="$t('translate.category')" v-model="selectedCategory" :items="workCategoriesList"
              :displayField="'description'" :valueField="'work_category_id'" />
          </div>
          <div v-show="total > 0" class="md-layout-item md-small-size-100 md-size-15">
            <AmountsDropdown :label="$t('label.show_as')" v-model="showAmounts" @input="onAmountChange" />
          </div>

          <div class="md-layout-item btn-row md-small-size-50">
            <div v-if="approvedBudget">
              <span style="color: red;">{{ $t('budget.approved') }}</span>
            </div>

            <div v-else>
              <md-button v-if="step === 'first'" class="md-raised md-success" @click="editBudget()">
                {{ $t('buttons.add') }}
              </md-button>

              <template v-if="step === 'second' && total > 0">
                <md-button class="md-success" @click="populate">
                  {{ $t('buttons.populate') }}
                </md-button>
                <md-button class="md-success" @click="showAllTreatments()">
                  {{ $t('buttons.show_works') }}
                </md-button>
              </template>
            </div>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-progress-spinner v-show="showSpinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
        <md-table class='"paginated-table table-striped table-hover' :value="budgetList" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('budget.category')" md-sort-by="work_category">
              {{ item.work_category }}
            </md-table-cell>
            <md-table-cell :md-label="amountLabel" md-sort-by="amount_kgs" md-numeric>
              {{ item.amount_kgs / showAmounts | numFormat }}
            </md-table-cell>
            <md-table-cell v-if="step === 'second' || step === 'third'" :md-label="$t('budget.works_count')"
              md-sort-by="treatment_count" md-numeric>
              {{ item.treatment_count }}
            </md-table-cell>
            <md-table-cell v-if="step === 'third'" :md-label="$t('budget.approved')" md-sort-by="approved_on">
              {{ item.approved_on | dateFormat }}
            </md-table-cell>

            <md-table-cell :md-label="$t('tables.actions')">
              <div class="cell-actions">
                <md-button v-for="(button, index) in buttonConfigs(item)" v-if="button.condition" :key="index"
                  :class="button.class" :title="button.title" @click.native="button.action(item)">
                  {{ !button.icon ? button.title : "" }}
                  <md-icon v-if="button.icon">{{ button.icon }}</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <div v-show="showTreatmentsForm" :style="{ width: '100%', height: '100%' }">
      <AllTreatments :params="treatmentsFormParams" :budget="selectedBudget" @close="toggleTreatmentsForm(false)" />
    </div>

    <md-dialog :md-active.sync="showEditForm" :md-click-outside-to-close="false">
      <md-dialog-title>{{ editFormTitle }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='toggleEditForm(false)'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <BudgetEditForm :budget="selectedBudget" @close="toggleEditForm(false)" @saved="onBudgetSave" />
    </md-dialog>
  </div>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '@/pages/Dropdowns/RegionsDropdown.vue'
  import AmountsDropdown from '@/pages/Components/AmountsDropdown.vue'
  import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
  import BudgetEditForm from './BudgetEditForm.vue'
  import AllTreatments from './AllTreatments'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/mixins/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'budget-list-form',
    data() {
      return {
        showSpinner: true,
        selectedWorklist: null,
        selectedRegion: null,
        selectedCategory: null,
        editFormTitle: '',
        treatmentsFormParams: {},
        showEditForm: null,
        showTreatmentsForm: false,

        selectedBudget: null,
        showAmounts: 1000,
        amountLabel: '',

        currentSort: 'work_category',
        currentSortOrder: 'asc',
      }
    },

    props: {
      step: { default: 'first', type: String },
    },

    components: {
      RegionsDropdown,
      AmountsDropdown,
      BaseDropdown,
      BudgetEditForm,
      AllTreatments
    },

    mounted() {
      this.toggleSpinner(true)
      this.onAmountChange(this.showAmounts, this.$t("label.short_thousands"))
      this.$store.dispatch('RESET_TREATMENTS')
      this.$store.dispatch('GET_WORK_CATEGORIES_LIST', true)
      this.reloadBudgetList()
    },

    methods: {
      reloadBudgetList() {
        this.toggleSpinner(true)
        this.$store.dispatch('LOAD_BUDGET_LIST').then(() => {
          this.toggleSpinner(false)
        })
      },

      toggleSpinner(state) {
        this.showSpinner = state
      },

      toggleEditForm(state) {
        this.showEditForm = state
      },

      toggleTreatmentsForm(state) {
        this.showTreatmentsForm = state
        this.toggleSpinner(false)
        if (!state) this.reloadBudgetList()
      },

      onAmountChange(value, desc) {
        this.amountLabel = `${this.$t('budget.amount')} ${desc}`
      },

      editBudget(item) {
        // check whether we are adding new or editing existing item
        this.editFormTitle = this.$t(`route.budget_${!item ? 'add' : 'upd'}`)
        this.selectedBudget = !item ? { // New item
          year: this.selectedYear,
          fk_region: this.selectedRegion,
          fk_work_category: this.selectedCategory,
        } : item
        this.toggleEditForm(true)
      },

      onBudgetSave() {
        this.toggleEditForm(false)
        this.reloadBudgetList()
      },

      showTreatments(params) {
        this.treatmentsFormParams = params
        this.toggleTreatmentsForm(true)
        //this.showTreatmentsForm = true
      },

      showAllTreatments() {
        this.selectedBudget = null
        this.showTreatments({ mode: 'all', work_list_id: this.selectedWorklist, region: this.selectedRegion, year: this.selectedYear })
      },

      showAssignedTreatments(item) {
        this.selectedBudget = item
        this.showTreatments({ mode: 'current', work_list_id: this.selectedWorklist, region: this.selectedRegion, year: this.selectedYear })
      },

      async populate() {
        this.toggleSpinner(true)
        let params = {
          work_list_id: this.selectedWorklist,
          region: this.selectedRegion,
          year: this.selectedYear
        }
        const res = await this.$store.dispatch('POPULATE_TREATMENTS', params)
        await Swal.fire(this.$t('messages.populated'), `${res} ${this.$t('messages.entries_added')}`, 'success')
        //const res = 1
        this.reloadBudgetList()
        if (res > 0) {
          params['mode'] = 'assigned'
          this.selectedBudget = null
          this.showTreatments(params)
          this.toggleSpinner(false)
        }
      },

      async toggleApprove(item) {
        this.toggleSpinner(true)
        //let count = 0
        //let errCount = 0
        let err = false
        //this.budgetList.forEach(budget => {
        const today = new Date()
        const theBudget = {
          year: item.year,
          fk_region: item.fk_region,
          fk_work_category: item.fk_work_category,
          amount_kgs: item.amount_kgs,
          amount_usd: item.amount_usd,
          version: item.version,
          approved: Number(!item.approved),
          approved_on: !item.approved ? today : null,
          approved_by_user_id: !item.approved ? this.me.id : null,
          updated_at: today
        }
        const id = item.budget_id
        //delete item.budget_id

        //this.$store.dispatch('TOGGLE_BUDGET_APPROVAL', budget_id).then(() => { })
        await this.$store.dispatch('UPD_BUDGET', { id: id, theBudget: theBudget }).then(
          () => { /*count++*/ },
          (err) => { err = true /*errCount++ */ })
        //})
        const alert = {
          icon: !err ? 'success' : 'warning',
          text: this.$t(`${!item.approved ? 'label.was_approved' : 'label.was_revoked'}`, { cat: `${item.work_category}` }),
          footer: ''//errCount ? `${errCount} errors` : ''
        }
        Swal.fire(alert)
        this.reloadBudgetList()
      },

      buttonConfigs(item) {
        return [
          {
            condition: this.step === 'first' && !item.approved,
            title: this.$t('buttons.edit'),
            class: 'md-just-icon md-default md-simple',
            icon: 'edit',
            action: () => this.editBudget(item)
          },
          /*{
            condition: this.step === 'first' && !item.approved && this.amIAdmin,
            title: this.$t('buttons.delete'),
            class: 'md-just-icon md-danger md-simple',
            icon: 'delete',
            action: () => this.handleDelete(item)
          },*/
          {
            condition: this.step === 'second' && item.treatment_count > 0,
            title: this.$t('buttons.show_works'),
            class: 'md-just-icon md-success md-simple',
            icon: 'preview',
            action: () => this.showAssignedTreatments(item)
          },
          {
            condition: this.step === 'third' && (item.treatment_count > 0 || item.approved),
            title: item.approved ? this.$t('buttons.revoke') : this.$t('buttons.approve'),
            class: ['md-raised', 'md-sm', item.approved ? 'md-primary' : 'md-success'],
            action: () => this.toggleApprove(item)
          },
        ];
      },
    },

    computed: {
      ...mapState({
        me: (state) => state.Login.me,
        savedWorksList: (state) => state.TitleList.worksLists,
        workCategoriesList: (state) => state.Dropdowns.work_categories,
        treatmentsList: (state) => state.TitleList.treatmentsList,
        //budgetList: (state) => state.Budgets.list
        //wpList: (state) => state.TitleList.assignedTreatments,
      }),
      ...mapGetters([
        'amIAdmin', 'budgetsByCategory', 'budgetApproved', 'budgetSummary',
        'plansByRegion', 'budgetsByRegionAndYear', 'getAssignedTreatments'
      ]),
      selectedYear() {
        return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist)?.year)
      },
      budgetList() {
        return this.budgetsByCategory(this.selectedRegion, this.selectedYear, this.selectedCategory)
        .sort((a, b) => a.work_category.localeCompare(b.work_category, undefined, { sensitivity: 'base' }))
      },
      total() {
        return this.budgetList.length
      },
      summaryInfo() {
        return this.budgetSummary(this.selectedRegion, this.selectedYear)
      },
      approvedBudget() {
        return this.summaryInfo.approved
      },
      footerTable() {
        return [
          this.$t('label.total'),
          `${this.$t('label.categories')}: ${numFormat(this.summaryInfo.totalRecords, 0)}`,
          `${this.$t('budget.amount')}: ${numFormat(this.summaryInfo.totalKGS / this.showAmounts)}`,
          // `${this.summaryInfo.approved ? 'Approved' : 'Not Approved'}`
        ]
      },
    },

    watch: {
      step(value) {
        //console.log('step', this.step)
        //this.reloadTreatmentsList()
      },
    }
  }
</script>
<style lang="scss">
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cell-actions {
  display: flex;
  justify-content: flex-end;
  //flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.assigned-treatments-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>